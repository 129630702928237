import Cookies from 'js-cookie';
import './index.scss';

class tlCookiesConsent {
  constructor( attachElement, opts = {} ) {
    this.element = attachElement || document.body;

    Object.assign(this, {
      cookieName: '_pdpa_accept',
      cookieExpires: 30,
      cookieValue: '1',
      bannerSelector: '.travelanium-cookies-consent-banner',
      bannerAcceptButonSelector: '.travelanium-cookies-consent-banner__button--accept',
      callToAction: {
        'accept': {
          label: 'ACCEPT',
          href: 'javascript:;',
        },
      },
      acceptedCallback: function(){},
      requiredCallback: function(){},
      bannerTemplate: function() {
        return `
<div class="travelanium-cookies-consent-banner">
  <div class="travelanium-cookies-consent-banner__inner">
    <div class="travelanium-cookies-consent-banner__title">Cookies & Privacy</div>
    <div class="travelanium-cookies-consent-banner__text">We use cookies to enchance your visit to our site and to bring you advertisements that might interest you Privacy Policy to learn how to manage cookies on this site.</div>
    <div class="travelanium-cookies-consent-banner__call-to-action">
      <a class="travelanium-cookies-consent-banner__button travelanium-cookies-consent-banner__button--readmore" href="javascript!;"">PRIVACY POLICY</a>
      <a class="travelanium-cookies-consent-banner__button travelanium-cookies-consent-banner__button--accept" href="javascript:;">ACCEPT</a>
    </div>
  </div>
</div>
`;
      },
    }, opts);

    /**
     * callback ที่ทำงานทันทีโดยไม่รอการยินยอม
     */
    this.requiredCallback();

    if (this.isAccepted()) {
      /**
       * callback ที่จะรันเมื่อมีการยินยอมเอาไว้ก่อนแล้ว
       */
      this._accepted();
    } else {
      /**
       * หากยังไม่เคยมีการยินยอม ให้ขึ้นแบนเนอร์แจ้งเตือนให้ทราบ
       * และ bind event ให้ปุ่ม accept
       */
      this.element.insertAdjacentHTML('beforeEnd', this.bannerTemplate());
      let banner = document.querySelector( this.bannerSelector );
      banner.classList.add('show');
      banner.addEventListener('click', ev => {
        ev.preventDefault();
        let target = ev.target;
        if (target.closest( this.bannerAcceptButonSelector )) {
          this.accept();
        }
      });
    }
  }

  accept() {
    let n = this.cookieName;
    let e = this.cookieExpires;
    let v = this.cookieValue;

    Cookies.set(n, v, {
      expires: e,
    });

    let banner = document.querySelector( this.bannerSelector );
    if (banner) {
      banner.classList.add('hide');
      setTimeout(() => {
        banner.parentElement.removeChild(banner);
      }, 1000);
    }

    this._accepted();
  }

  isAccepted() {
    return !!Cookies.get(this.cookieName);
  }

  hasBanner() {
    return document.querySelector( this.bannerSelector ) ? true : false;
  }

  _accepted() {
    if ('function' === typeof this.acceptedCallback) {
      this.acceptedCallback();
    }
  }
}

export default tlCookiesConsent;